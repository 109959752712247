import { PropertiesHyphen } from 'csstype';

interface FluidScreen {
  screenMin?: number;
  screenMax?: number;
}
const fluidScreenDefaults = { screenMin: 320, screenMax: 1024 };
export const fluid = (
  key: keyof PropertiesHyphen,
  min = 16,
  max = 22,
  {
    screenMin = fluidScreenDefaults.screenMin,
    screenMax = fluidScreenDefaults.screenMax,
  }: FluidScreen = fluidScreenDefaults
) => {
  return `
  ${key}: ${min}px;
  @media screen and (min-width: ${screenMin}px) {
    ${key}: calc(${min}px + ${max - min} * ((100vw - ${screenMin}px) / ${screenMax - screenMin}));
  }
  @media screen and (min-width: ${screenMax}px) {
    ${key}: ${max}px;
  }
`;
};
export function fluidSpacing(
  key: 'margin' | 'padding',
  min: [number, number],
  max: typeof min,
  screenProps?: FluidScreen
): string;
export function fluidSpacing(
  key: 'margin',
  min: [number, 'auto'],
  max: typeof min,
  screenProps?: FluidScreen
): string;
export function fluidSpacing(
  key: 'margin' | 'padding',
  min: [number, number, number],
  max: typeof min,
  screenProps?: FluidScreen
): string;
export function fluidSpacing(
  key: 'margin',
  min: [number, 'auto', number],
  max: typeof min,
  screenProps?: FluidScreen
): string;
export function fluidSpacing(
  key: 'margin' | 'padding',
  min: [number, number, number, number],
  max: typeof min,
  screenProps?: FluidScreen
): string;
export function fluidSpacing(
  key: 'margin',
  min: [number, 'auto', number, number],
  max: typeof min,
  screenProps?: FluidScreen
): string;
export function fluidSpacing(
  key: 'margin',
  min: [number, number, number, 'auto'],
  max: typeof min,
  screenProps?: FluidScreen
): string;
export function fluidSpacing(
  key: 'margin',
  min: [number, 'auto', number, 'auto'],
  max: typeof min,
  screenProps?: FluidScreen
): string;
export function fluidSpacing<
  T extends
    | [number, number | 'auto']
    | [number, number | 'auto', number]
    | [number, number | 'auto', number, number | 'auto']
>(key: 'margin' | 'padding', min: T, max: typeof min, screenProps?: FluidScreen) {
  const keyTop = `${key}-top` as keyof PropertiesHyphen;
  const keyRight = `${key}-right` as keyof PropertiesHyphen;
  const keyBottom = `${key}-bottom` as keyof PropertiesHyphen;
  const keyLeft = `${key}-left` as keyof PropertiesHyphen;
  if (min.length === 2) {
    return (
      fluid(keyTop, min[0], max[0], screenProps) +
      fluid(keyBottom, min[0], max[0], screenProps) +
      (min[1] === 'auto'
        ? `${keyLeft}: auto; ${keyRight}: auto;`
        : fluid(keyLeft, min[1] as any, max[1] as any, screenProps) +
          fluid(keyRight as any, min[1] as any, max[1] as any, screenProps))
    );
  }
  if (min.length === 3) {
    return (
      fluid(keyTop, min[0], max[0], screenProps) +
      (min[1] === 'auto'
        ? `${key}-left: auto; ${key}-right: auto;`
        : fluid(keyLeft, min[1] as any, max[1] as any, screenProps) +
          fluid(keyRight as any, min[1] as any, max[1] as any, screenProps)) +
      fluid(keyBottom, min[2], max[2], screenProps)
    );
  }
  if (min.length === 4) {
    return (
      fluid(keyTop, min[0], max[0], screenProps) +
      (min[1] === 'auto'
        ? `${key}-right: auto;`
        : fluid(keyRight as any, min[1] as any, max[1] as any, screenProps)) +
      fluid(keyBottom, min[2], max[2], screenProps) +
      (min[3] === 'auto'
        ? `${key}-left: auto;`
        : fluid(keyLeft, min[3] as any, max[3] as any, screenProps))
    );
  }
  return '';
}
