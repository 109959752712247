import React from 'react';
import { useLocation } from 'react-router-dom';
import { analytics } from '../lib/firebase';
import styled from 'styled-components/macro';
import { fluid, fluidSpacing } from '../styles/helpers';

import DesktopScreenshotSrc from '../img/notebird-screenshot-desktop.png';
import MobileScreenshotSrc from '../img/notebird-screenshot-mobile.png';

import NoWrap from '../components/NoWrap';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  ${fluidSpacing('padding', [96, 12, 0], [192, 32, 0])}
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
const Heading = styled.h1`
  margin: 0;
  ${fluid('padding-bottom', 16, 32)}
  ${fluid('font-size', 28, 86)}
  ${fluid('line-height', 30, 83)}
`;
const SubHeading = styled.div`
  max-width: 768px;
  ${fluid('padding-bottom', 24, 48)}
  ${fluid('font-size', 16, 32)}
  ${fluid('line-height', 22, 42)}
  color: ${({ theme }) =>
    theme.grey400};
`;
const TrialCopy = styled.div`
  ${fluid('padding-bottom', 24, 32)}
  ${fluid('font-size', 16, 32)}
  color: ${({ theme }) => theme.grey700};
  font-weight: bold;
  strong {
    color: ${({ theme }) => theme.grey900};
  }
`;
const PrimaryButton = styled.a`
  display: inline-block;
  position: relative;
  width: 100%;
  ${fluidSpacing('padding', [16, 24], [24, 48])}
  @media (min-width: 720px) {
    margin: 0 auto;
    width: auto;
  }
  ${fluid('margin-bottom', 12, 24)}
  ${fluid('border-radius', 8, 12)}
  text-align: center;
  ${fluid('font-size', 24, 32)}
  font-weight: bold;
  background-color: ${({ theme }) => theme.primary500};
  color: white;
  box-shadow: 2px 4px 24px rgba(28, 85, 160, 0.18);

  &:hover {
    background-color: ${({ theme }) => theme.primary600};
    transform: scale(1.02);
  }
  transition: transform 400ms ${({ theme }) => theme.easeDecelerate};
`;
const ScheduleDemo = styled.div`
  ${fluid('padding-bottom', 46, 64)}
  ${fluid('font-size', 16, 24)}
  color: ${({ theme }) => theme.grey400};
  a {
    color: ${({ theme }) => theme.grey400};
    text-decoration: underline;
    &:hover {
      color: ${({ theme }) => theme.primary500};
    }
  }
`;
const DesktopScreenshot = styled.img`
  /* width: calc(100% - 24px); */
  width: 100%;
  border-radius: 2% 2% 0 0;
  box-shadow: ${({ theme }) => theme.imgShadow};
  @media (max-width: 719px) {
    display: none;
  }
`;
const MobileScreenshot = styled.img`
  /* width: calc(100% - 16px); */
  width: 100%;
  border-radius: 14% 14% 0 0;
  box-shadow: ${({ theme }) => theme.imgShadow};
  @media (min-width: 720px) {
    display: none;
  }
`;

const Jumbotron = () => {
  const location = useLocation();

  return (
    <Wrapper className='fade-in'>
      <Heading>
        Pastoral Care <NoWrap>Software You'll Love</NoWrap>
      </Heading>

      <SubHeading>
        Spreadsheets are for numbers, not people. <NoWrap>Notebird makes organizing</NoWrap>{' '}
        <NoWrap>pastoral care easy.</NoWrap>
      </SubHeading>

      <TrialCopy>
        <strong>14 Day Trial</strong> · No Credit Card Required
      </TrialCopy>

      <PrimaryButton
        href={`https://my.notebird.app/signup/${location.search}`}
        onClick={() =>
          analytics.logEvent('landing_event', { event: 'select "Give Notebird a Try" (top)' })
        }
      >
        Give Notebird a Try
      </PrimaryButton>

      <ScheduleDemo>
        Or{' '}
        <a
          href='/'
          onClick={e => {
            e.preventDefault();
            window.Calendly.initPopupWidget({
              url:
                'https://calendly.com/notebird/notebird-demo?text_color=1f2933&primary_color=046adb',
            });
            analytics.logEvent('landing_event', { event: 'select "Schedule a Demo" (top)' });
            return false;
          }}
        >
          Schedule a Demo
        </a>
      </ScheduleDemo>

      <DesktopScreenshot src={DesktopScreenshotSrc} alt='Screenshot of Notebird - Desktop' />
      <MobileScreenshot src={MobileScreenshotSrc} alt='Screenshot of Notebird - Mobile' />
    </Wrapper>
  );
};
export default Jumbotron;
