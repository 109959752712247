import React from 'react';
import { useLocation } from 'react-router-dom';
import { analytics } from '../lib/firebase';
import styled from 'styled-components/macro';
import { fluid, fluidSpacing } from '../styles/helpers';
import { useIsSynagogue } from '../App';

import LogosBlock from '../components/LogosBlock';
import { ReactComponent as BethesdaSvg } from '../svg/logos/churches/bethesda.svg';
import { ReactComponent as FriendshipSvg } from '../svg/logos/churches/friendship.svg';
import { ReactComponent as HopeSvg } from '../svg/logos/churches/hope.svg';
import { ReactComponent as KingStreetSvg } from '../svg/logos/churches/king-street.svg';
import { ReactComponent as SaintMarksSvg } from '../svg/logos/churches/saint-marks.svg';
import { ReactComponent as TrinitySvg } from '../svg/logos/churches/trinity.svg';

import { ReactComponent as ThumbUpSvg } from '../svg/icons/thumb_up.svg';

import Spacer from '../components/Spacer';
import NoWrap from '../components/NoWrap';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  ${fluidSpacing('padding', [32, 12, 48], [64, 32, 96])}
`;
const Heading = styled.h2`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  ${fluid('margin-bottom', 32, 48)}
  ${fluid('font-size', 22, 48)}
  ${fluid('line-height', 24, 52)}
`;
const BulletBlock = styled.div`
  text-align: left;
  ${fluid('margin-bottom', 48, 96)}
`;
const BulletRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  ${fluid('font-size', 16, 28)}
  font-weight: bold;
  color: ${({ theme }) => theme.primary500};
  svg {
    flex-shrink: 0;
    ${fluid('margin-right', 8, 16)}
    ${fluid('width', 24, 48)}
    height: auto;
  }
`;
const PrimaryButton = styled.a`
  display: inline-block;
  position: relative;
  width: 100%;
  ${fluidSpacing('padding', [16, 24], [24, 48])}
  @media (min-width: 720px) {
    margin: 0 auto;
    width: auto;
  }
  ${fluid('margin-bottom', 12, 24)}
  ${fluid('border-radius', 8, 12)}
  text-align: center;
  ${fluid('font-size', 24, 32)}
  font-weight: bold;
  background-color: ${({ theme }) => theme.primary500};
  color: white;
  box-shadow: 2px 4px 24px rgba(28, 85, 160, 0.18);

  &:hover {
    background-color: ${({ theme }) => theme.primary600};
    transform: scale(1.02);
  }
  transition: transform 400ms ${({ theme }) => theme.easeDecelerate};
`;
const ScheduleDemo = styled.div`
  ${fluid('padding-bottom', 46, 64)}
  ${fluid('font-size', 16, 24)}
  line-height: 1.33;
  a {
    white-space: nowrap;
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.dividerColor};
    font-weight: bold;
    &:hover {
      text-decoration-color: ${({ theme }) => theme.primary500};
    }
  }
`;

interface Props {}
const BottomLogoCloud: React.FC<Props> = () => {
  const isSynagogue = useIsSynagogue();
  const location = useLocation();

  return (
    <Wrapper>
      {!isSynagogue && (
        <>
          <LogosBlock
            logos={[BethesdaSvg, FriendshipSvg, HopeSvg, KingStreetSvg, SaintMarksSvg, TrinitySvg]}
          />
          <Spacer mobileHeight='32px' height='64px' />
        </>
      )}

      <Heading>
        {!isSynagogue && 'Churches around the world are organizing care a new way.'} When will you{' '}
        <NoWrap>make the switch?</NoWrap>
      </Heading>

      <BulletBlock>
        <BulletRow>
          <ThumbUpSvg />
          Give Notebird a try for 14 days free of charge
        </BulletRow>
        <BulletRow>
          <ThumbUpSvg />
          {isSynagogue
            ? 'Sync your ShulCloud data with our new exporter tool'
            : "We'll import your existing congregant list"}
        </BulletRow>
        <BulletRow>
          <ThumbUpSvg />
          Free guided onboarding and 5-star support
        </BulletRow>
      </BulletBlock>

      <PrimaryButton
        href={`https://my.notebird.app/signup/${location.search}`}
        onClick={() =>
          analytics.logEvent('landing_event', { event: 'select "Start Your Free Trial" (bottom)' })
        }
      >
        Start Your Free Trial
      </PrimaryButton>

      <ScheduleDemo>
        Still have questions?{' '}
        <a
          href='/'
          className='intercom-launcher'
          onClick={() =>
            analytics.logEvent('landing_event', {
              event: 'select "Chat with Us" (bottom)',
            })
          }
        >
          Chat with Us
        </a>{' '}
        or{' '}
        <a
          href='/'
          onClick={e => {
            e.preventDefault();
            window.Calendly.initPopupWidget({
              url:
                'https://calendly.com/notebird/notebird-demo?text_color=1f2933&primary_color=046adb',
            });
            analytics.logEvent('landing_event', { event: 'select "Schedule a Demo" (bottom)' });
            return false;
          }}
        >
          Schedule a Demo
        </a>
      </ScheduleDemo>
    </Wrapper>
  );
};
export default BottomLogoCloud;
