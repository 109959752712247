import { DefaultTheme } from 'styled-components';

const defaultTheme: DefaultTheme = {
  // Special Colors
  backgroundColor: '#F9FAFB',
  backgroundAccent: '#EFF5FB',
  highlightColor: '#CCEAFF',
  dividerColor: '#E2EDF8',

  // Notebird Colors
  primary000: '#E6F6FF',
  primary100: '#BAE3FF',
  primary200: '#7CC4FA',
  primary300: '#47A3F3',
  primary400: '#2186EB',
  primary500: '#046ADB',
  primary600: '#0452B8',
  primary700: '#034094',
  primary800: '#013075',
  primary900: '#011F54',

  grey000: '#F5F7FA',
  grey100: '#E4E7EB',
  grey200: '#CBD2D9',
  grey300: '#9AA5B1',
  grey400: '#7B8794',
  grey500: '#616E7C',
  grey600: '#52606D',
  grey700: '#3E4C59',
  grey800: '#323F4B',
  grey900: '#1F2933',

  danger000: '#FFEEEE',
  danger100: '#FACDCD',
  danger200: '#F29B9B',
  danger300: '#E66A6A',
  danger400: '#D64545',
  danger500: '#BA2525',
  danger600: '#A61B1B',
  danger700: '#911111',
  danger800: '#780A0A',
  danger900: '#610404',

  // Easing
  easeStandard: 'cubic-bezier(0.4, 0, 0.2, 1)',
  easeSharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
  easeDecelerate: 'cubic-bezier(0, 0, 0.2, 1)',
  easeAccelerate: 'cubic-bezier(0.4, 0, 1, 1)',

  // Shadows
  defaultShadow: '0px 0px 12px 6px rgba(28, 85, 160, 0.06)',
  imgShadow: '0px 0px 24px 12px rgba(28, 85, 160, 0.12)',
};

export default defaultTheme;
