import React from 'react';
import { useLocation } from 'react-router-dom';
import { analytics } from '../lib/firebase';
import styled from 'styled-components/macro';
import { fluid, fluidSpacing } from '../styles/helpers';

import { ReactComponent as NotebirdSvg } from '../svg/notebird-logo.svg';
import Expanded from '../components/Expanded';

const Wrapper = styled.nav`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  ${fluidSpacing('padding', [8, 8], [16, 32])}
  background-color: white;
  box-shadow: ${({ theme }) => theme.defaultShadow};
`;
const NotebirdLogo = styled(NotebirdSvg)`
  ${fluid('width', 128, 192)}
`;
const SignIn = styled.a`
  flex-shrink: 0;
  ${fluidSpacing('padding', [12, 16], [16, 32])}
  white-space: nowrap;
  font-weight: bold;
  ${fluid('font-size', 14, 24)}
`;
const TryIt = styled.a`
  flex-shrink: 0;
  ${fluidSpacing('padding', [12, 16], [16, 32])}
  background-color: ${({ theme }) => theme.primary500};
  ${fluid('border-radius', 4, 8)}
  white-space: nowrap;
  font-weight: bold;
  color: white;
  ${fluid('font-size', 16, 24)}
`;

interface Props {}
const Nav: React.FC<Props> = () => {
  const location = useLocation();

  return (
    <Wrapper>
      <NotebirdLogo />
      <Expanded />
      <SignIn
        href={`https://my.notebird.app/login/${location.search}`}
        onClick={() => analytics.logEvent('landing_event', { event: 'select "Sign In"' })}
      >
        Sign In
      </SignIn>
      <TryIt
        href={`https://my.notebird.app/signup/${location.search}`}
        onClick={() => analytics.logEvent('landing_event', { event: 'select "Try it Free" (nav)' })}
      >
        Try it Free
      </TryIt>
    </Wrapper>
  );
};
export default Nav;
