import React, { useLayoutEffect, createContext, useContext, useEffect } from 'react';
import { analytics } from './lib/firebase';
// Style Stuff
import { ThemeProvider } from 'styled-components/macro';
import defaultTheme from './styles/defaultTheme';
import Normalize from './styles/Normalize';
import BaseStyles from './styles/BaseStyles';
import IntercomOverride from './styles/IntercomOverride';
// Page Sections
import Nav from './sections/Nav';
import Jumbotron from './sections/Jumbotron';
import TopLogoCloud from './sections/TopLogoCloud';
import BeforeNotebird from './sections/BeforeNotebird';
import WithNotebird from './sections/WithNotebird';
import Pricing from './sections/Pricing';
import Quotes from './sections/Quotes';
import BottomLogoCloud from './sections/BottomLogoCloud';
import Footer from './sections/Footer';

// IsSynagogue Context
// Visitors from notebird.faith are treated as Synagogues
// ...all others are assumed to be Churches
const isSynagogueContext = createContext<boolean>(false);
export const useIsSynagogue = () => useContext(isSynagogueContext);

// App component
function App() {
  // Determine if is synagogue (coming from notebird.faith)
  const host = window.location.hostname.toLowerCase();
  const isSynagogue = host === 'notebird.faith';

  // Register session
  useEffect(() => {
    analytics.logEvent('landing_event', {
      event: isSynagogue ? 'begin session (synagogue)' : 'begin session (church)',
    });
  }, [isSynagogue]);

  // Launch/boot Intercom
  useLayoutEffect(() => {
    setTimeout(() => {
      window.Intercom &&
        window.Intercom('boot', {
          app_id: 'ojdu8zpl',
          custom_launcher_selector: '.intercom-launcher',
        });
    }, 10);
  }, []);

  return (
    <isSynagogueContext.Provider value={isSynagogue}>
      <ThemeProvider theme={defaultTheme}>
        {/* Style Stuff */}
        <Normalize />
        <BaseStyles />
        <IntercomOverride />

        {/* Sections */}
        <Nav />
        <Jumbotron />
        <TopLogoCloud />
        <BeforeNotebird />
        <WithNotebird />
        <Pricing />
        <Quotes />
        <BottomLogoCloud />

        {/* Footer */}
        <Footer />
      </ThemeProvider>
    </isSynagogueContext.Provider>
  );
}

export default App;
