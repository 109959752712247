import React from 'react';
import styled from 'styled-components/macro';
import { fluid, fluidSpacing } from '../styles/helpers';
import { useIsSynagogue } from '../App';

import QuotesSvgSrc from '../svg/icons/quotes.svg';
// Synagogue profile pics
import AsherKnightSrc from '../img/customers/asher-knight.jpg';
// Church profile pics
import ToddLovelaceSrc from '../img/customers/todd-lovelace.jpg';
import BrianElliottSrc from '../img/customers/brian-elliott.jpg';

import NoWrap from '../components/NoWrap';

const Wrapper = styled.div`
  ${fluidSpacing('padding', [32, 12, 0], [64, 32, 0])}
  background-color: white;
  border-top: 3px solid ${({ theme }) => theme.dividerColor};
  border-bottom: 3px solid ${({ theme }) => theme.dividerColor};
  box-shadow: ${({ theme }) => theme.defaultShadow};
`;
const Heading = styled.h2`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  ${fluid('margin-bottom', 16, 24)}
  text-align: center;
  ${fluid('font-size', 22, 48)}
  ${fluid('line-height', 24, 52)}
`;
const Copy = styled.div`
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  ${fluid('margin-bottom', 32, 64)}
  text-align: center;
  ${fluid('font-size', 16, 28)}
  ${fluid('line-height', 22, 36)}
  color: ${({ theme }) => theme.grey400};
`;
const Row = styled.div`
  margin: 0 auto;
  @media (min-width: 720px) {
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 1024px;
  }
`;
const RowSpacer = styled.div`
  flex-shrink: 0;
  ${fluid('width', 24, 48)}
  @media (max-width: 719px) {
    display: none;
  }
`;
const Quote = styled.div`
  position: relative;
  ${fluidSpacing('padding', [12, 16], [24, 32])}
  border: 3px solid ${({ theme }) => theme.dividerColor};
  ${fluid('border-radius', 6, 12)}
  background-color: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.primary800};
  ${fluid('font-size', 18, 32)}
  &:before {
    content: '';
    ${fluid('top', -8, -16)}
    ${fluid('left', -12, -24)}
    position: absolute;
    ${fluid('width', 24, 48)}
    ${fluid('height', 24, 48)}
    background-image: url('${QuotesSvgSrc}');
    background-size: contain;
    background-repeat: no-repeat;
  }
  &:after {
    content: '';
    ${fluid('bottom', -8, -16)}
    ${fluid('right', -12, -24)}
    position: absolute;
    ${fluid('width', 24, 48)}
    ${fluid('height', 24, 48)}
    background-image: url('${QuotesSvgSrc}');
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(180deg);
  }
`;
const QuoteTail = styled.div`
  position: absolute;
  ${fluid('bottom', -16, -24)}
  left: 4px;
  ${fluid('height', 32, 48)}
  ${fluid('width', 32, 48)}
  transform: scaleX(0.33);
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    transform: rotate(45deg);
    border-bottom-right-radius: 30%;
    border-top-left-radius: 100%;
    border-bottom: 6px solid ${({ theme }) => theme.dividerColor};
    border-right: 6px solid ${({ theme }) => theme.dividerColor};
  }
`;
const CreditRow = styled.div`
  display: flex;
  align-items: center;
  ${fluidSpacing('margin', [24, 0, 32], [48, 0, 64])}
  img {
    ${fluid('width', 48, 64)}
    ${fluid('margin-right', 8, 16)}
    height: auto;
    border-radius: 50%;
    box-shadow: ${({ theme }) => theme.defaultShadow};
  }
  .name {
    ${fluid('font-size', 18, 32)}
    color: ${({ theme }) => theme.primary800};
    font-weight: bold;
  }
  .role {
    ${fluid('font-size', 16, 24)}
    color: ${({ theme }) => theme.grey400};
    strong {
      color: ${({ theme }) => theme.grey500};
    }
  }
`;

interface Props {}
const Quotes: React.FC<Props> = () => {
  const isSynagogue = useIsSynagogue();
  return isSynagogue ? <SynagogueQuotes /> : <ChurchQuotes />;
};
export default Quotes;

const SynagogueQuotes = () => (
  <Wrapper>
    <Heading>Synagogues already love Notebird</Heading>
    <Copy>
      Teams of all sizes already use Notebird daily.{' '}
      <NoWrap>
        <strong>Here's why:</strong>
      </NoWrap>
    </Copy>

    <Row>
      <div style={{ flex: 1 }}>
        <Quote>
          We were using Google sheets but missing things all the time. It was a mess.{' '}
          <strong>Notebird is increasing our pastoral responsiveness.</strong>
          <QuoteTail />
        </Quote>
        <CreditRow>
          <img src={AsherKnightSrc} alt='Asher Knight' />
          <div>
            <div className='name'>Rabbi Asher Knight</div>
            <div className='role'>
              <strong>Senior Rabbi</strong> · <NoWrap>Temple Beth El</NoWrap>
            </div>
          </div>
        </CreditRow>
      </div>
    </Row>
  </Wrapper>
);

const ChurchQuotes = () => (
  <Wrapper>
    <Heading>Churches already love Notebird</Heading>
    <Copy>
      From 20 members to 20,000 members, pastors and churches of all sizes love Notebird.{' '}
      <NoWrap>
        <strong>Here's why:</strong>
      </NoWrap>
    </Copy>

    <Row>
      <div style={{ flex: 1 }}>
        <Quote>
          I'd get excited about keeping up with excel and logging everything and then after a week
          or two that would stop because it wasn't easy.{' '}
          <strong>I use Notebird daily and love it!</strong>
          <QuoteTail />
        </Quote>
        <CreditRow>
          <img src={ToddLovelaceSrc} alt='Todd Lovelace' />
          <div>
            <div className='name'>Todd Lovelace</div>
            <div className='role'>
              <strong>Family Pastor</strong> · <NoWrap>Skyline Church</NoWrap>
            </div>
          </div>
        </CreditRow>
      </div>

      <RowSpacer />

      <div style={{ flex: 1 }}>
        <Quote>
          Notebird is exactly what we were looking for. It has become{' '}
          <strong>the backbone of our pastoral care ministry.</strong>
          <QuoteTail />
        </Quote>
        <CreditRow>
          <img src={BrianElliottSrc} alt='Brian Elliott' />
          <div>
            <div className='name'>Brian Elliott</div>
            <div className='role'>
              <strong>Care Director</strong> · <NoWrap>Frontline Church</NoWrap>
            </div>
          </div>
        </CreditRow>
      </div>
    </Row>
  </Wrapper>
);
