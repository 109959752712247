import { createGlobalStyle } from 'styled-components/macro';

const IntercomOverride = createGlobalStyle`
  div#intercom-container {
    iframe[name="intercom-borderless-frame"]{
      right: 0;
      bottom: 0;
      padding: 0 10px 90px 0;
    }
    iframe[name="intercom-borderless-frame"],
    iframe[name="intercom-chat-frame"] {
      background: radial-gradient(100% 100% at 100% 100%, rgba(4, 82, 184, 0.14) 60%, rgba(4, 106, 219, 0) 100%);
    }
  }
`;

export default IntercomOverride;
