import React from 'react';
import styled from 'styled-components/macro';
import { fluidSpacing, fluid } from '../styles/helpers';

const StyledLogosBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
  svg {
    width: 100%;
    height: 100%;
    ${fluid('max-width', 96, 192)}
    ${fluid('max-height', 32, 64)}
    ${fluidSpacing('margin', [10, 16], [24, 32])}
  }
`;

interface Props {
  logos: React.FC[];
}
const LogosBlock: React.FC<Props> = ({ logos }) => {
  return (
    <StyledLogosBlock>
      {logos.map((Logo, i) => (
        <Logo key={i} />
      ))}
    </StyledLogosBlock>
  );
};
export default LogosBlock;
