import React from 'react';
import styled from 'styled-components/macro';
import { fluid, fluidSpacing } from '../styles/helpers';

import SpreadsheetsSrc from '../img/messy-spreadsheets.png';
import EmailSrc from '../img/endless-email.png';
import { ReactComponent as CloseSvg } from '../svg/icons/close.svg';

import Spacer from '../components/Spacer';
import NoWrap from '../components/NoWrap';

const Wrapper = styled.div`
  ${fluidSpacing('padding', [32, 12, 0], [64, 32, 32])}
`;
const BeforeRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  font-weight: bold;
  color: ${({ theme }) => theme.grey400};
  ${fluid('font-size', 16, 28)}
  span {
    padding: 0 12px;
  }

  div.line {
    flex: 1;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.grey200};
    border-radius: 2px;
  }
`;
const Heading = styled.h2`
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  ${fluid('margin-bottom', 16, 24)}
  text-align: center;
  ${fluid('font-size', 22, 48)}
  ${fluid('line-height', 24, 52)}
`;
const Copy = styled.div`
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
  ${fluid('font-size', 16, 28)}
  ${fluid('line-height', 22, 36)}
  color: ${({ theme }) => theme.grey400};
`;
const Row = styled.div`
  margin: 0 auto 64px;
  @media (min-width: 720px) {
    display: flex;
    width: 100%;
    max-width: 1024px;
  }
`;
const RowSpacer = styled.div`
  flex-shrink: 0;
  ${fluid('width', 48, 64)}
  @media (max-width: 719px) {
    display: none;
  }
`;
const Img = styled.img`
  display: block;
  margin: 0 auto 24px;
  ${fluid('width', 192, 320)}
  height: 100%;
  ${fluid('border-radius', 12, 24)}
  box-shadow: ${({ theme }) => theme.defaultShadow};
`;
const SubHeading = styled.div`
  ${fluid('margin-bottom', 12, 16)}
  font-weight: bold;
  ${fluid('font-size', 20, 36)}
`;
const SubCopy = styled(Copy)`
  text-align: left !important;
`;
const BulletRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  ${fluid('font-size', 18, 32)}
  font-weight: bold;
  color: ${({ theme }) => theme.danger500};
  svg {
    flex-shrink: 0;
    ${fluid('margin-right', 8, 16)}
    ${fluid('width', 24, 48)}
    height: auto;
  }
`;

interface Props {}
const BeforeNotebird: React.FC<Props> = () => {
  return (
    <Wrapper>
      <BeforeRow>
        <div className='line' />
        <span>Before Notebird</span>
        <div className='line' />
      </BeforeRow>

      <Heading>Are you frustrated with your current pastoral care process?</Heading>
      <Copy>
        We understand—it's easy for people to <NoWrap>fall through the cracks.</NoWrap>
        <Spacer mobileHeight='8px' height='12px' />
        Trying to track care for tens, hundreds, or thousands of people is challenging, especially
        when you don't <NoWrap>have the right tool.</NoWrap>
      </Copy>
      <Spacer mobileHeight='48px' height='96px' />

      {/* Messy Spreadsheets */}
      <Row>
        <Img src={SpreadsheetsSrc} />
        <RowSpacer />
        <div>
          <SubHeading>Spreadsheets and documents are messy and get out of control.</SubHeading>
          <SubCopy>
            Countless columns and rows make it hard to track care history and see the latest
            updates. All that complexity causes your team to stop making updates.
          </SubCopy>
          <Spacer mobileHeight='16px' height='24px' />
          <BulletRow>
            <CloseSvg />
            Difficult to manage
          </BulletRow>
          <BulletRow>
            <CloseSvg />
            Time consuming
          </BulletRow>
          <BulletRow>
            <CloseSvg />
            Gets worse over time
          </BulletRow>
        </div>
      </Row>

      {/* Endless Email */}
      <Row>
        <Img src={EmailSrc} />
        <RowSpacer />
        <div>
          <SubHeading>Care needs get lost in endless email threads and Slack channels.</SubHeading>
          <SubCopy>
            Without a dedicated solution, churches are forced to use other communication channels to
            manage pastoral care. Endless email threads and Slack replies make it difficult to keep
            track of congregants.
          </SubCopy>
          <Spacer mobileHeight='16px' height='24px' />
          <BulletRow>
            <CloseSvg />
            Hard to review care history
          </BulletRow>
          <BulletRow>
            <CloseSvg />
            Scattered care information
          </BulletRow>
          <BulletRow>
            <CloseSvg />
            Not linked to a congregant profile
          </BulletRow>
        </div>
      </Row>
    </Wrapper>
  );
};
export default BeforeNotebird;
