import React from 'react';
import styled from 'styled-components/macro';
import { fluidSpacing, fluid } from '../styles/helpers';

const Wrapper = styled.div`
  ${fluidSpacing('padding', [16, 24], [24, 32])}
  background-color: ${({ theme }) => theme.grey900};
  text-align: right;
  ${fluid('font-size', 14, 24)}
  color: ${({ theme }) => theme.grey400};
`;

const Footer: React.FC = () => {
  return <Wrapper>&copy; {new Date().getFullYear()} Notebird Corp.</Wrapper>;
};
export default Footer;
