import React from 'react';
import styled from 'styled-components/macro';
import { fluid, fluidSpacing } from '../styles/helpers';
import { useIsSynagogue } from '../App';

import LogosBlock from '../components/LogosBlock';
// Synagogue logos
import { ReactComponent as BethElSvg } from '../svg/logos/synagogues/bethel.svg';
import { ReactComponent as ShirHadashSvg } from '../svg/logos/synagogues/hadash.svg';
import { ReactComponent as JeremiahSvg } from '../svg/logos/synagogues/jeremiah.svg';
import { ReactComponent as KenesethSvg } from '../svg/logos/synagogues/keneseth.svg';
import { ReactComponent as SinaiSvg } from '../svg/logos/synagogues/sinai.svg';
import { ReactComponent as TempleSvg } from '../svg/logos/synagogues/temple.svg';
// Church logos
import { ReactComponent as brenhamsSvg } from '../svg/logos/churches/brenhams.svg';
import { ReactComponent as cccSvg } from '../svg/logos/churches/ccc.svg';
import { ReactComponent as FrontlineSvg } from '../svg/logos/churches/frontline.svg';
import { ReactComponent as gccSvg } from '../svg/logos/churches/gcc.svg';
import { ReactComponent as SkylineSvg } from '../svg/logos/churches/skyline.svg';
import { ReactComponent as TwoPillarsSvg } from '../svg/logos/churches/two-pillars.svg';

// Misc components
import NoWrap from '../components/NoWrap';

// Array-ize logos
const synagogueLogos = [BethElSvg, ShirHadashSvg, JeremiahSvg, KenesethSvg, SinaiSvg, TempleSvg];
const churchLogos = [brenhamsSvg, cccSvg, FrontlineSvg, gccSvg, SkylineSvg, TwoPillarsSvg];

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  border-top: 3px solid ${({ theme }) => theme.dividerColor};
  border-bottom: 3px solid ${({ theme }) => theme.dividerColor};
  box-shadow: ${({ theme }) => theme.defaultShadow};
`;
const Container = styled.div`
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  ${fluidSpacing('padding', [32, 8, 24], [64, 16, 48])}
  text-align: center;
`;
const Copy = styled.div`
  font-weight: bold;
  ${fluid('margin-bottom', 16, 32)}
  ${fluid('font-size', 16, 28)}
`;

interface Props {}
const TopLogoCloud: React.FC<Props> = () => {
  const isSynagogue = useIsSynagogue();

  return (
    <Wrapper>
      <Container>
        <Copy>
          {isSynagogue ? 'Synagogues' : 'Care teams'} everywhere finally have a tool to{' '}
          <NoWrap>complement their love for people.</NoWrap>
        </Copy>

        <LogosBlock logos={isSynagogue ? synagogueLogos : churchLogos} />
      </Container>
    </Wrapper>
  );
};
export default TopLogoCloud;
