import React, { useState, useRef } from 'react';
import { analytics } from '../lib/firebase';
import styled from 'styled-components/macro';
import { fluid, fluidSpacing } from '../styles/helpers';
import { useIsSynagogue } from '../App';

import { ReactComponent as UpdatesSvg } from '../svg/features/notebird-updates.svg';
import { ReactComponent as TasksSvg } from '../svg/features/notebird-tasks.svg';
import { ReactComponent as ActivitySvg } from '../svg/features/notebird-activity.svg';
import { ReactComponent as ReportsSvg } from '../svg/features/notebird-reports.svg';
import { ReactComponent as IntegrationsSvg } from '../svg/features/notebird-integrations.svg';
import { ReactComponent as IntegrationsSynagoguesSvg } from '../svg/features/notebird-integrations-synagogues.svg';
import { ReactComponent as ArrowSvg } from '../svg/icons/arrow_right.svg';

import Spacer from '../components/Spacer';
import NoWrap from '../components/NoWrap';

const Wrapper = styled.div`
  ${fluidSpacing('padding', [32, 12, 0], [64, 32, 32])}
  background-color: white;
  border-top: 3px solid ${({ theme }) => theme.dividerColor};
  border-bottom: 3px solid ${({ theme }) => theme.dividerColor};
  box-shadow: ${({ theme }) => theme.defaultShadow};
`;
const WithRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  font-weight: bold;
  color: ${({ theme }) => theme.grey400};
  ${fluid('font-size', 16, 28)}
  span {
    padding: 0 12px;
  }

  div.line {
    flex: 1;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.grey200};
    border-radius: 2px;
  }
`;
const Heading = styled.h2`
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  ${fluid('margin-bottom', 16, 24)}
  text-align: center;
  ${fluid('font-size', 22, 48)}
  ${fluid('line-height', 24, 52)}
`;
const Copy = styled.div`
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  text-align: center;
  ${fluid('font-size', 16, 28)}
  ${fluid('line-height', 22, 36)}
  color: ${({ theme }) => theme.grey400};
`;
const Row = styled.div`
  margin: 0 auto 96px;
  @media (min-width: 720px) {
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 1024px;
  }
`;
const RowSpacer = styled.div`
  flex-shrink: 0;
  ${fluid('width', 48, 64)}
  @media (max-width: 719px) {
    display: none;
  }
`;
const Svg = styled.svg`
  flex-shrink: 0;
  display: block;
  margin: 0 auto 8px;
  width: 85%;
  max-width: 420px;
  @media (min-width: 720px) {
    ${fluid('max-width', 264, 384, { screenMin: 720 })}
  }
`;
const CopyBlock = styled.div`
  position: relative;
  ${fluid('padding-left', 16, 22)}
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    ${fluid('width', 4, 6)}
    height: 100%;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.primary500};
  }
`;
const SubHeading = styled.div`
  ${fluid('margin-bottom', 12, 16)}
  font-weight: bold;
  ${fluid('font-size', 20, 36)}
`;
const SubCopy = styled(Copy)`
  text-align: left !important;
`;

interface Props {}
const WithNotebird: React.FC<Props> = () => {
  const isSynagogue = useIsSynagogue();

  return (
    <Wrapper>
      <WithRow>
        <div className='line' />
        <span>With Notebird</span>
        <div className='line' />
      </WithRow>

      <Heading>
        Clergy and their teams are organized, prepared, and on the same{' '}
        <NoWrap>page with Notebird.</NoWrap>
      </Heading>
      <Copy>
        Store conversation notes, assign follow-ups, and track life milestones{' '}
        <strong>all in one place.</strong>
      </Copy>
      <Spacer mobileHeight='48px' height='96px' />

      {/* Updates - Feature */}
      <Row>
        <Svg as={UpdatesSvg} />
        <RowSpacer />
        <div>
          <CopyBlock>
            <SubHeading>Organize conversation notes</SubHeading>
            <SubCopy>
              Easily add updates when you hear about important life events. Notebird tracks care
              history—giving you the insight to care more intentionally.
            </SubCopy>
          </CopyBlock>
          <Spacer mobileHeight='16px' height='24px' />
          <Expandable
            title='Notebird has flexible permission settings so you can control who sees each update.'
            description='User Roles restrict visibility of selected updates and tasks from certain users. Groups allow you to select which users see confidential information.'
            tag='updates'
          />
        </div>
      </Row>

      {/* Tasks - Feature */}
      <Row>
        <Svg as={TasksSvg} style={{ order: 3 }} />
        <RowSpacer style={{ order: 2 }} />
        <div style={{ order: 1 }}>
          <CopyBlock>
            <SubHeading>Set a follow-up task</SubHeading>
            <SubCopy>
              Tasks in Notebird remind you and your care team to check in with people, so no one
              falls through the cracks.
            </SubCopy>
          </CopyBlock>
          <Spacer mobileHeight='16px' height='24px' />
          <Expandable
            title='Did you know you can assign tasks to other users in Notebird?'
            description='Assign a task to yourself or other team members to check-in, follow-up, or send flowers. Customize your tasks and make sure nobody falls through the cracks.'
            // description={
            //   isSynagogue
            //     ? 'Assign a task to yourself or other team members to check-in, follow-up, or send flowers. Customize your tasks and make sure nobody falls through the cracks.'
            //     : 'Assign a task to yourself or other team members to check-in, follow-up, send flowers, or send grief booklets. Customize your tasks and make sure nobody falls through the cracks.'
            // }
            tag='tasks'
          />
        </div>
      </Row>

      {/* Activity - Feature */}
      <Row>
        <Svg as={ActivitySvg} />
        <RowSpacer />
        <div>
          <CopyBlock>
            <SubHeading>See the whole picture</SubHeading>
            <SubCopy>
              Notebird's activity page provides a birds-eye view of everything happening in your
              organization. No other tool brings all your care updates into one place.
            </SubCopy>
          </CopyBlock>
          <Spacer mobileHeight='16px' height='24px' />
          <Expandable
            title='Teams are using the activity page to help run their care meetings!'
            description='Our activity page is like your very own care dashboard. Teams talk through the activity feed during their meetings to make sure they are not missing moments that matter.'
            tag='activity'
          />
        </div>
      </Row>

      {/* Reports - Feature */}
      <Row>
        <Svg as={ReportsSvg} style={{ order: 3 }} />
        <RowSpacer style={{ order: 2 }} />
        <div style={{ order: 1 }}>
          <CopyBlock>
            <SubHeading>Care Reports in a single click</SubHeading>
            <SubCopy>
              Spending hours sifting through spreadsheets, emails and group texts to put together a
              pastoral care report are a thing of the past.
            </SubCopy>
          </CopyBlock>
          <Spacer mobileHeight='16px' height='24px' />
          <Expandable
            title='Leaders now have an instant birds eye view on all the care happening within their organization.'
            description='Run a Care Report to see who has a sobriety milestone, got a new job, or lost a loved-one in the last year. Customize Notebird and track exactly what care metrics matter to your organization.'
            tag='reports'
          />
        </div>
      </Row>

      {/* Integrations - Feature */}
      <Row>
        {isSynagogue ? <Svg as={IntegrationsSynagoguesSvg} /> : <Svg as={IntegrationsSvg} />}
        <RowSpacer />
        <div>
          <CopyBlock>
            <SubHeading>Integrate with ease</SubHeading>
            <SubCopy>
              Sync people from your existing {isSynagogue ? 'Synagogue' : 'Church'} Management
              Software into Notebird.
            </SubCopy>
          </CopyBlock>
          <Spacer mobileHeight='16px' height='24px' />
          <Expandable
            title={
              isSynagogue
                ? 'Connect your ShulCloud account to Notebird in less than 30 seconds.'
                : 'Connect your Breeze or Planning Center account to Notebird in less than 30 seconds.'
            }
            description={
              isSynagogue
                ? 'Don’t use Shulcloud? Our team can securely import your people from a spreadsheet—for free.'
                : 'Don’t use these platforms? Our team can securely import your people from a spreadsheet—for free.'
            }
            tag='integrations'
          />
        </div>
      </Row>
    </Wrapper>
  );
};
export default WithNotebird;

// Expandable Block
const ExpandableRow = styled.div<{ expanded: boolean }>`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  color: ${({ theme }) => theme.grey500};
  &:hover {
    color: ${({ theme }) => theme.grey700};
  }
  svg {
    flex-shrink: 0;
    ${fluid('margin-right', 4, 8)}
    ${fluid('margin-left', -8, -16)}
    ${fluid('width', 24, 48)}
    height: auto;
    transform: rotate(${({ expanded }) => (expanded ? '90deg' : 0)});
    transition: transform 300ms ${({ theme }) => theme.easeStandard};
  }
`;
const ExpandableTitle = styled.div`
  ${fluid('font-size', 16, 24)}
  font-weight: bold;
`;
const ExpandableLearnMore = styled.span`
  text-decoration: underline;
  cursor: pointer;
  color: ${({ theme }) => theme.primary500};
`;
const ExpandableDescription = styled.div`
  ${fluid('font-size', 16, 20)}
  ${fluid('padding-left', 18, 39)}
  ${fluid('margin-top', 8, 12)}
  color: ${({ theme }) => theme.grey400};
`;
interface ExpandableProps {
  title: string;
  description: string;
  tag: string; // for analytics
}
const Expandable: React.FC<ExpandableProps> = ({ title, description, tag }) => {
  const [expanded, setExpanded] = useState(false);
  const i = useRef(0);
  return (
    <>
      <ExpandableRow
        onClick={() => {
          setExpanded(prev => !prev);
          if (i.current === 0) {
            analytics.logEvent('landing_event', { event: `select "Learn more" (${tag})` });
          }
          i.current++;
        }}
        expanded={expanded}
      >
        <ArrowSvg />
        <ExpandableTitle>
          {title}{' '}
          {!expanded && <ExpandableLearnMore className='fade-in'>Learn more</ExpandableLearnMore>}
        </ExpandableTitle>
      </ExpandableRow>
      {expanded && <ExpandableDescription className='fade-in'>{description}</ExpandableDescription>}
    </>
  );
};
