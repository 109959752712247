import React, { useState, useMemo, useLayoutEffect, useRef, useEffect, Fragment } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { fluid, fluidSpacing } from '../styles/helpers';

import { ReactComponent as ThumbUpSvg } from '../svg/icons/thumb_up.svg';
import { ReactComponent as ExpandSvg } from '../svg/icons/expand.svg';

import Spacer from '../components/Spacer';

const Wrapper = styled.div`
  ${fluidSpacing('padding', [32, 12], [64, 32])}
`;
const Row = styled.div`
  margin: 0 auto;
  @media (min-width: 820px) {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1024px;
  }
`;
const RowSpacer = styled.div`
  flex-shrink: 0;
  ${fluid('width', 48, 64)}
  @media (max-width: 719px) {
    display: none;
  }
`;
const Heading = styled.h2`
  ${fluid('margin-bottom', 16, 24)}
  ${fluid('font-size', 22, 48)}
  ${fluid('line-height', 24, 52)}
`;
const Copy = styled.div`
  ${fluid('margin-bottom', 16, 24)}
  ${fluid('font-size', 16, 28)}
  ${fluid('line-height', 22, 36)}
  color: ${({ theme }) => theme.grey400};
`;
const BulletRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  ${fluid('font-size', 16, 28)}
  font-weight: bold;
  color: ${({ theme }) => theme.primary500};
  svg {
    flex-shrink: 0;
    ${fluid('margin-right', 8, 16)}
    ${fluid('width', 24, 48)}
    height: auto;
  }
`;

interface Props {}
const Pricing: React.FC<Props> = () => {
  const history = useHistory();
  const scrollRef = useRef<HTMLDivElement>(null);

  const [, path = '', count = ''] = window.location.pathname.split('/');
  const scrollToPricing = path === 'pricing';
  const parsedCount = Number(count) || 1;

  useLayoutEffect(() => {
    const easing = 0.15;
    const navHeightOffset = 48;
    function step() {
      let scrollTo = (scrollRef.current?.offsetTop ?? 0) - navHeightOffset;
      if (scrollRef.current && scrollTo >= 0) {
        // Reset scroll destination if original is out of reach
        const maxScroll = document.body.scrollHeight - window.innerHeight;
        if (scrollTo > maxScroll) {
          scrollTo = maxScroll;
        }
        // Exit out if reached destination
        if (window.scrollY === scrollTo) return;

        // Calculate and handle scroll
        const scrollDelta = scrollTo - window.scrollY;
        let scrollAmount = scrollDelta * easing;
        // Make sure scroll is always moving by at least 1px
        if (Math.abs(scrollAmount) < 1) {
          scrollAmount = scrollDelta < 0 ? -1 : 1;
        }
        const scrollDestination = window.scrollY + scrollAmount;
        window.scroll(0, scrollDestination);
        window.requestAnimationFrame(step);
      }
    }

    if (scrollToPricing) {
      // Remove from location bar
      history.replace('/' + window.location.search);
      // Run scroller
      console.log('run scroller');
      window.requestAnimationFrame(step);
    }
  }, [history, scrollToPricing]);

  return (
    <Wrapper ref={scrollRef}>
      <Row>
        <div>
          <Heading>Simple pricing</Heading>
          <Copy>
            Whether flying solo or flocking together, Notebird has an easy subscription model to fit
            all shapes and sizes.
            <Spacer mobileHeight='16px' height='24px' />
            All plans include:
          </Copy>
          <BulletRow>
            <ThumbUpSvg />
            Unlimited congregants
          </BulletRow>
          <BulletRow>
            <ThumbUpSvg />
            Free onboarding and training
          </BulletRow>
          <BulletRow>
            <ThumbUpSvg />
            Access on desktop AND smartphone
          </BulletRow>
          <Spacer height='24px' />
        </div>

        <RowSpacer />

        <PricingPanel defaultCount={parsedCount} />
      </Row>
    </Wrapper>
  );
};
export default Pricing;

// Pricing Panel
const Panel = styled.div`
  padding: 24px 24px 16px;
  ${fluidSpacing('padding', [16, 24, 8], [32, 48, 16])}
  ${fluid('border-radius', 8, 16)}
  box-shadow: ${({ theme }) => theme.imgShadow};
  background-color: white;

  flex-shrink: 0;
  display: block;
  margin: 0 auto 8px;
  width: 100%;
  max-width: 420px;
  @media (min-width: 820px) {
    ${fluid('max-width', 264, 420, { screenMin: 720 })}
  }
`;
const TabRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Tab = styled.button<{ isActive: boolean }>`
  position: relative;
  appearance: none;
  outline: none;
  ${fluidSpacing('padding', [8, 12], [8, 16])}
  margin: 0 4px;
  ${fluid('margin-bottom', 24, 48)}
  font-weight: bold;
  ${fluid('font-size', 16, 24)}
  color: ${({ theme }) => theme.grey600};
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 4px;
    border-radius: 8px;
    background-color: ${({ isActive, theme }) => (isActive ? theme.primary500 : theme.grey100)};
  }
`;
const Price = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;

  ${fluid('font-size', 24, 32)}
  color: ${({ theme }) => theme.grey400};
  strong {
    ${fluid('font-size', 48, 86)}
    ${fluid('line-height', 48, 86)}
    color: ${({ theme }) => theme.grey900};
  }
`;
const SubPrice = styled.div`
  text-align: center;
  ${fluid('font-size', 16, 20)}
  color: ${({ theme }) => theme.grey400};
  a {
    white-space: nowrap;
    font-weight: bold;
  }
`;
const InputRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${fluid('margin-top', 24, 32)}

  font-weight: bold;
  ${fluid('font-size', 18, 24)}
  color: ${({ theme }) => theme.grey900};

  span {
    font-weight: normal;
    ${fluid('font-size', 16, 18)}
    color: ${({ theme }) => theme.grey400};
  }

  input[type='text'] {
    appearance: none;
    outline: none;
    flex-shrink: 0;
    ${fluid('width', 48, 64)}
    margin: 0 12px;
    padding: 8px 2px 8px 0;
    text-align: center;
    border-radius: 4px;
    border: 2px solid ${({ theme }) => theme.grey100};
    background-color: ${({ theme }) => theme.grey000};
    font-weight: bold;
    ${fluid('font-size', 16, 24)}
  }
`;
const SliderRow = styled.div<{ bumpThumb: boolean }>`
  display: flex;
  width: 100%;
  ${fluid('margin-top', 12, 24)}

  input[type='range'] {
    flex: 1;
    appearance: none;
    outline: none;
    &::-webkit-slider-runnable-track {
      background-color: ${({ theme }) => theme.grey200};
      height: 8px;
      border-radius: 8px;
    }
    &::-moz-range-track {
      background-color: ${({ theme }) => theme.grey200};
      height: 8px;
      border-radius: 8px;
    }
    &::-webkit-slider-thumb {
      cursor: pointer;
      appearance: none;
      border: none;
      margin-top: -8px;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.primary500};
      transition: transform 800ms ${({ theme }) => theme.easeStandard};
      transform: translateX(${({ bumpThumb }) => (bumpThumb ? '42%' : 0)});
    }
    &::-moz-range-thumb {
      cursor: pointer;
      appearance: none;
      border: none;
      margin-top: -8px;
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.primary500};
      transition: transform 800ms ${({ theme }) => theme.easeStandard};
      transform: translateX(${({ bumpThumb }) => (bumpThumb ? '42%' : 0)});
    }
  }
`;
const NeedMoreUsers = styled.div<{ show: boolean }>`
  ${fluid('margin-top', 8, 12)}
  text-align: center;
  ${fluid('font-size', 16, 20)}
  color: ${({ theme }) => theme.grey700};
  a {
    white-space: nowrap;
    font-weight: bold;
  }
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
`;
const expandIn = keyframes`
    0% { opacity: 0; transform: scaleY(0.6); }
  100% { opacity: 1; transform: scaleY(1); }
`;
const BreakdownGrid = styled.div`
  transform-origin: top center;
  animation: ${expandIn} 350ms ${({ theme }) => theme.easeDecelerate};

  display: inline-grid;
  grid-template-columns: 1fr repeat(5, auto);
  grid-template-rows: auto;
  ${fluid('column-gap', 2, 4)}
  row-gap: 4px;
  width: 100%;
  ${fluid('margin-top', 8, 12)}

  color: ${({ theme }) => theme.grey700};

  .heading {
    margin-bottom: 4px;
    padding-bottom: 2px;
    border-bottom: 2px solid ${({ theme }) => theme.grey100};
    font-size: 18px;
    font-weight: bold;
    color: ${({ theme }) => theme.grey400};
  }
  .tier {
    padding-left: 0;
    font-weight: bold;
  }
  .faded {
    opacity: 0.25;
  }
  .right {
    justify-self: end;
  }
  small {
    color: ${({ theme }) => theme.grey400};
  }
  span {
    color: ${({ theme }) => theme.grey300};
    margin: 0 2px;
  }
  strong {
    color: ${({ theme }) => theme.grey900};
  }
  em {
    font-style: normal;
    color: ${({ theme }) => theme.grey300};
  }
`;
const BreakdownToggleRow = styled.div`
  display: flex;
  justify-content: center;
`;
const BreakdownToggle = styled.button`
  appearance: none;
  outline: none;

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  ${fluid('margin-top', 12, 16)}
  padding: 6px 12px;
  border-radius: 6px;

  text-align: center;
  ${fluid('font-size', 16, 18)}
  color: ${({ theme }) => theme.grey400};

  &:hover {
    color: ${({ theme }) => theme.grey700};
  }

  svg {
    flex-shrink: 0;
    height: 24px;
    width: auto;
  }
`;

const SLIDER_MIN = 1;
const SLIDER_MAX = 50;
const STRIPE_PRICING_STRUCTURE = [
  { min: 30, month: 2, year: 1 },
  { min: 20, month: 4, year: 3 },
  { min: 10, month: 6, year: 5 },
  { min: 2, month: 8, year: 7 },
  { min: 1, month: 12, year: 10 },
];
const PricingPanel = ({ defaultCount }: { defaultCount: number }) => {
  const [annual, setAnnual] = useState(true);
  const [showBreakdown, setShowBreakdown] = useState(false);

  const [sliderVal, setSliderVal] = useState(defaultCount);
  const [inputVal, setInputVal] = useState(defaultCount.toString());

  const cost = useMemo(() => {
    let currentPrice = 0;
    for (let i = 1; i <= sliderVal; i++) {
      currentPrice +=
        STRIPE_PRICING_STRUCTURE.find(({ min }) => i >= min)?.[annual ? 'year' : 'month'] ?? 0;
    }
    currentPrice = Math.round(currentPrice * 100) / 100;
    const perUser = Math.round((currentPrice / sliderVal) * 100) / 100;
    return {
      dollars: Math.floor(currentPrice),
      cents: Math.round((currentPrice % 1) * 100),
      perUser,
      perUserDollars: Math.floor(perUser),
      perUserCents: Math.round((perUser % 1) * 100),
    };
  }, [annual, sliderVal]);

  // Slider hint animation when only has 1 user
  const [bumpThumb, setBumpThumb] = useState(false);
  const [preventBump, setPreventBump] = useState(false);
  const runAnimation = !preventBump && sliderVal === 1;
  useEffect(() => {
    let interval = -1;
    let timeout = -1;

    // Running anim
    if (runAnimation) {
      interval = setInterval(() => {
        setBumpThumb(true);
        timeout = setTimeout(() => {
          setBumpThumb(false);
        }, 800);
      }, 3600);
    }
    // Clear anim
    else {
      clearInterval(interval);
      interval = -1;
      clearTimeout(timeout);
      timeout = -1;
      setBumpThumb(false);
    }

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [runAnimation]);

  return (
    <Panel>
      <TabRow>
        <Tab isActive={annual} onClick={() => setAnnual(true)}>
          Annual
        </Tab>
        <Tab isActive={!annual} onClick={() => setAnnual(false)}>
          Monthly
        </Tab>
      </TabRow>

      <Price>
        <span style={{ alignSelf: 'flex-start' }}>$</span>
        <strong>{cost.dollars}</strong>
        <span>/mo</span>
      </Price>

      <SubPrice style={{ opacity: annual ? 1 : 0 }}>paid yearly</SubPrice>

      <InputRow>
        For
        <input
          type='text'
          value={inputVal}
          maxLength={2}
          onChange={e => {
            setInputVal(e.target.value);
            const val = parseInt(e.target.value);
            if (val) {
              const clippedVal = Math.min(Math.max(val, SLIDER_MIN), SLIDER_MAX);
              setSliderVal(clippedVal);
            }
          }}
          onBlur={() => {
            setInputVal(sliderVal.toString());
          }}
        />
        {sliderVal > 1 ? 'users' : 'user'}
      </InputRow>

      <SliderRow bumpThumb={bumpThumb}>
        <input
          type='range'
          min={SLIDER_MIN}
          max={SLIDER_MAX}
          value={sliderVal}
          onChange={e => {
            setSliderVal(parseInt(e.target.value));
            setInputVal(e.target.value);
          }}
          onMouseDown={() => setPreventBump(true)}
          onMouseUp={() => setPreventBump(false)}
        />
      </SliderRow>

      <NeedMoreUsers show={sliderVal === SLIDER_MAX}>
        More than {SLIDER_MAX} users?{' '}
        <a href='/' className='intercom-launcher'>
          Contact us
        </a>
      </NeedMoreUsers>

      {showBreakdown && (
        <BreakdownGrid>
          <div className='heading'>Tier</div>
          <div className='heading' style={{ gridColumn: '2 / 7' }}>
            Per user/mo
          </div>

          {STRIPE_PRICING_STRUCTURE.map((_, i) => {
            const idx = STRIPE_PRICING_STRUCTURE.length - i - 1;
            const { min, month, year } = STRIPE_PRICING_STRUCTURE[idx];
            const amount = annual ? year : month;

            const max = STRIPE_PRICING_STRUCTURE[idx - 1]?.min - 1;
            const minQty = min === 1 ? 1 : 0;
            const maxQty = max ? max - min + 1 : min;
            const qty = sliderVal - (min - 1);
            const clampedQty = Math.max(minQty, Math.min(maxQty, qty));

            const className = sliderVal < min ? 'faded' : '';

            return (
              <Fragment key={min}>
                {/* Tier */}
                <div className={className + ' tier'}>
                  {min === 1 && '1st user'}
                  {![1, 30].includes(min) && `Users ${min}-${max}`}
                  {min === 30 && '30+ users'}
                </div>
                {/* Subtotal (price/mo) */}
                <div className={className + ' right'}>{clampedQty}</div>
                <span className={className}>×</span>
                <div className={className}>
                  <small>$</small>
                  {amount}
                  <em>/mo</em>
                </div>
                <span className={className}>=</span>
                <div className={className}>
                  <small>$</small>
                  <strong>{amount * clampedQty}</strong>
                </div>
              </Fragment>
            );
          })}
        </BreakdownGrid>
      )}

      <BreakdownToggleRow>
        <BreakdownToggle onClick={() => setShowBreakdown(prev => !prev)}>
          {showBreakdown && <ExpandSvg style={{ transform: 'rotateZ(180deg)' }} />}
          {showBreakdown ? 'Hide' : 'Show'} breakdown
          {!showBreakdown && <ExpandSvg />}
        </BreakdownToggle>
      </BreakdownToggleRow>
    </Panel>
  );
};
