import { createGlobalStyle, keyframes } from 'styled-components/macro';

const fadeIn = keyframes`
    0% { opacity: 0; }
  100% { opacity: 1; }
`;

const BaseStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  html, body {
    background-color: ${({ theme }) => theme.backgroundColor};
    font-family: 'Lato', sans-serif;
    color: ${({ theme }) => theme.grey900};
    height: 100%;
    width: 100%;
    width: 100vw;
  }
  button {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
  }
  a {
    color: ${({ theme }) => theme.primary500};
    text-decoration: none;
    cursor: pointer;
    &:hover{
      outline: none;
      text-decoration: underline;
    }
  }
  .hidden-mobile {
    @media(max-width: 1023px){
      display: none !important;
    }
  }
  .shown-mobile {
    @media(min-width: 1024px){
      display: none !important;
    }
  }
  .fade-in {
    opacity: 0;
    animation: ${fadeIn} 400ms ${({ theme }) => theme.easeDecelerate} 150ms forwards;
  }

`;

export default BaseStyles;
