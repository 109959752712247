import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/performance';
import 'firebase/analytics';

const config = {
  apiKey: 'AIzaSyBLwOr94QRQDQcaXCHLHlqqRdCzRw90VQ8',
  authDomain: 'notebird-firebase.firebaseapp.com',
  databaseURL: 'https://notebird-firebase.firebaseio.com',
  projectId: 'notebird-firebase',
  storageBucket: 'notebird-firebase.appspot.com',
  messagingSenderId: '759676155394',
  appId: '1:759676155394:web:a1783f4427a88066ba591b',
  measurementId: 'G-9ETRC7X8JZ',
};

// Initialize firebase (if isn't already)
!firebase.apps.length && firebase.initializeApp(config);

// Initialize Cloud Functions through Firebase
export const functions = firebase.functions();
// Initialize Performance Monitoring through Firebase
export const perf = firebase.performance();
// Initialize Analytics through Firebase
export const analytics = firebase.analytics();

export default firebase;
